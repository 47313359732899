import styled from 'styled-components';

export const ChartBarBasicStyled = styled.div`
  min-height: 32.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 2rem;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  background: #ffffff;

  .title {
    color: #2d3748;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
  }

  .circle {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: ${(props) => props.color};
  }

  .badge {
    background-color: #ffffff !important;
    border: 1px solid #cbd5e0;
  }

  .title-item {
    color: #2d3748;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .tag {
    color: #2d3748;
    text-align: right;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }

  .progress-bar {
    background-color: ${(props) => props.color};
  }
`;
